import axios from 'axios'

export function registerToken(token) {
    const baseURL = 'https://stg-api.kissan-tech.com'
    const authenticate = localStorage.getItem('authToken');
    return axios.post(`${baseURL}/addFirebaseToken/`, { token, user: 'admin' }, {
        headers: {
            Authorization: `Bearer ${authenticate}`,
        },
    });
}